<template>
  <div class="full-area" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="emitNext()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="contentx delay-entry" :class="{'is-vertical': this.data.fields.isVertical}">
      <div class="slider-bubbles">
        <div
          class="slider-bubble"
          :class="{'selected': index === form.selected[0]}"
          :style="{
            'width': `${(Math.abs(index-((data.fields.steps + 1) / 2)) + 2) * 10 / data.fields.steps}vh`,
            'height': `${(Math.abs(index-((data.fields.steps + 1) / 2)) + 2) * 10 / data.fields.steps}vh`,
            'border-color': chromaList[index],
            'background-color': index === form.selected[0] ? chromaList[index] : '#fff'
          }"
          v-for="index in data.fields.steps"
          :key="index"
          @click="selectThis(index)"
        ></div>
      </div>
      <div class="slider-labels">
        <div
          class="label-item"
          v-for="text in data.fields.textScale"
          :key="text"
        >
          {{text}}
        </div>
      </div>
      <div class="alt-option-wrapper" v-if="data.fields.altOption">
        <el-button
          class="gray-button"
          @click="selectThis(data.fields.altOption)"
        >{{data.fields.altOption}}</el-button>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
        :is-mandatory="data.fields.isMandatory"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
import chroma from 'chroma-js'

export default {
  name: 'Slider',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      form: {
        type: `likert`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        numberOfOptions: this.data.fields.steps,
        textScale: this.data.fields.textScale,
        id: this.data.sys.id,
        from: 1,
        to: this.data.fields.steps || this.data.fields.textScale.length,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        },
        hubSpotField: this.data.fields.hubSpotField
      },
      timer: null,
    }
  },

  methods: {
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      setTimeout(() => {
        this.next()
      }, 50)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value >= this.form.from && value <= this.form.to) {
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      this.locked = true
      var options = []
      for (var i = 1; i <= this.data.fields.steps; i++) {
        options.push(i)
      }
      if (this.data.fields.altOption) {
        options.push(this.data.fields.altOption)
      }
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.feedback = scores.feedback
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  computed: {
    chromaList () {
      return chroma.scale(['#f56c6c', '#88d8b0']).mode('lch').colors(this.data.fields.steps + 1)
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal) && Number.isInteger(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style lang="scss">
.gray-button {
  background: $gray-light !important;
  transition: all 0.2s ease-in-out;
  color: $text-main;
  font-size: 16px !important;
  &:hover {
    color: $text-main;
    border-color: rgba($gray-medium, 0.3) !important;
    background: rgba($gray-medium, 0.3) !important;
  }
}
</style>

<style scoped lang="scss">
.contentx {
  flex-direction: column;
  margin-bottom: 60px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 60px;
  align-items: center;
  @include breakpointHeight($square) {
    margin-bottom: 0;
  }

}

.slider-bubbles,
.slider-labels {
  width: 92%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  flex-orientation: row;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($tablet) {
    width: 460px;
  }
  @include breakpoint($desktop) {
    width: 380px;
  }
  @include breakpoint($fullhd) {
    width: 460px;
  }

  .slider-bubble {
    width: 40px;
    height: 40px;
    background: white;
    border: 3px solid gray;
    border-radius: 100%;

    @include breakpoint($tablet) {
      width: 150px;
      height: 50px;
    }
    &.selected {
      animation: standOut 0.4s ease-in-out forwards;
    }
  }
}

.slider-labels {
  font-weight: bold;
  font-size: 17px;
  color: $color-emphasis-alt;
  div {
    &:first-child {
      color: $color-emphasis;
    }
  }
}
.label-item {
  font-size: 18px;
  max-width: 37vw;
  text-align: left;
  &:last-child {
    text-align: right;
  }
  @include breakpointHeight($square) {
    line-height: 1;
    font-size: 15px;
  }
}

</style>
